<template>
  <div>
    <div class="flex items-center justify-between border-b pb-4">
      <h3 class="font-bold">{{ tenant.users.length }} usuarios de {{ project.current.name }}</h3>
    </div>

    <div class="mt-5">
      <div class="flex flex-wrap -m-4">
        <users-assign-table
          :project="project.current"
          :users="project.members"
          @reload-records="reloadData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import UsersAssignTable from './components/UsersAssignTable';
export default {
  name: 'project-users',
  components: { UsersAssignTable },
  computed: {
    ...mapState(['project', 'tenant']),
  },
  methods: {
    async reloadData(reload) {
      if (reload) await this.$store.dispatch('project/getMembers', this.project.current.id);
    },
  },
  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};
async function getPageData(to, next) {
  await store.dispatch('project/getMembers', to.params.project_id);
  next();
}
</script>
