<template>
  <div class="w-full">
    <template v-if="users.length">
      <table class="min-w-full">
        <thead>
          <tr>
            <th scope="col" class="px-6 py-3 text-left">Nombre de usuario</th>
            <th scope="col" class="px-6 py-3 text-left">Correo</th>
            <th scope="col" class="px-6 py-3 text-center">Asignar al Proyecto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="i" :class="{ 'bg-primary-100': i % 2 === 0 }">
            <td scope="col" class="px-6 py-3 text-left">{{ user.name }}</td>
            <td scope="col" class="px-6 py-3 text-left">{{ user.email }}</td>
            <td scope="col" class="px-6 py-3 text-center">
              <BaseSwitch
                :value="user.is_member"
                @on="handleSwitchOn(user)"
                @off="handleSwitchOff(user)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-start-4 col-span-6 mt-8 mx-auto">
          <img
            src="/img/search-no-results.png"
            alt="no-records"
            class="h-50 w-full object-cover md:w-80 mx-auto"
          />
          <h2 class="text-2xl text-center -mt-10">
            No hay usuarios registrados por el momento
          </h2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseSwitch from '@/components/BaseSwitch';
export default {
  name: 'users-assign-table',
  props: {
    project: { type: Object, default: null },
    users: { type: Array, default: null },
  },
  components: { BaseSwitch },
  methods: {
    async handleSwitchOn(user) {
      await this.$store.dispatch('project/addMember', {
        projectId: this.project.id,
        userId: user.id,
      });
      this.$emit('reload-records', true);
    },
    async handleSwitchOff(user) {
      await this.$store.dispatch('project/removeMember', {
        projectId: this.project.id,
        userId: user.id,
      });

      this.$emit('reload-records', true);
    },
  },
};
</script>
